import React, { useState } from 'react'

import { graphql } from 'gatsby'
import parse from 'html-react-parser'
import Layout from 'src/components/Layout'
import SEO from 'src/components/seo'

import Button from 'src/components/Button'

import 'src/styles/home.scss'
import 'src/styles/login.scss'

import fetch from 'src/utils/fetch'

export default ({ data }) => {
  const login_content = data.allRestApiApiRegistrationForm.edges[0].node.content
  const locale = data.allRestApiApiRegistrationForm.edges[0].node.locale

  const [registrationId, setRegistrationId] = useState('')
  const [password, setPassword] = useState('')
  const [errors, setErrors] = useState({ registration_id: '', password: '' })
  const [loading, setLoading] = useState(false)

  const handleSubmit = () => {
    const formData = new FormData()
    formData.append('registrant[registration_id]', registrationId)
    formData.append('registrant[password]', password)
    formData.append('registrant[locale]', locale)

    if (loading) return true
    setLoading(true)
    fetch('login', {
      method: 'POST',
      body: formData
    }).then(resultData => {
      if (resultData.success) {
        if (resultData.result && resultData.result.uuid) {
          window.localStorage.setItem('pru_uuid', resultData.result.uuid)
          window.location.href =
            locale === 'en'
              ? '/leaderboard'
              : `/${locale}/leaderboard`
        }
      } else {
        setErrors(resultData.errors)
      }
      setLoading(false)
    })
  }

  const renderLoginForm = () => (
    <div className="login-section">
      <div className="form-login">
        <div className="form-group">
          <label>{login_content.registration_id}*</label>
          <div className="fields">
            <input
              type="text"
              value={registrationId}
              onChange={e => setRegistrationId(e.target.value)}
            />
          </div>

          {errors.registration_id && (
            <div className="errors">{errors.registration_id}</div>
          )}
        </div>
        <div className="form-group">
          <label>{login_content.password}*</label>
          <div className="fields">
            <input
              type="password"
              value={password}
              onChange={e => setPassword(e.target.value)}
            />
          </div>

          {errors.password && <div className="errors">{errors.password}</div>}
        </div>

        <div className="login-button">
          <Button
            style={{ minWidth: 200 }}
            title={login_content.login}
            onClick={handleSubmit}
          ></Button>
        </div>
      </div>
    </div>
  )

  return (
    <Layout locale={'cn'}>
      <SEO title="Login" />
      <div className="home-banner-wrapper">
        <img src={login_content.home_banner} alt="" />
      </div>
      {renderLoginForm()}
    </Layout>
  )
}

export const GatsbyQuery = graphql`
  {
    allRestApiApiRegistrationForm(filter: { locale: { eq: "cn" } }) {
      edges {
        node {
          locale
          content {
            home_banner
            registration_id
            password
            password_note
            login
          }
        }
      }
    }
  }
`
